<template>
  <div class="az-content az-content-profile">
    <div class="container mn-ht-100p">
      <div class="az-content-left az-content-left-profile">
        <div class="az-profile-overview">
          <div class="az-img-user m-auto">
            <img alt="" src="../../../assets/clients/hb.png">
          </div><!-- az-img-user -->
          <div class="d-flex justify-content-between mg-b-20">
            <div>
              <h5 class="az-profile-name">{{accountDetails.name}}</h5>
              <p class="az-profile-name-text text-center">{{accountDetails.email}}</p>
            </div>
          </div>

          <div class="az-profile-bio">
            The National Hawker Federation is an association of street vendors across 28 States in India, with 1,188 Unions, including 11 Central Trade Unions & over 20 International Trade
            unions abroad.
          </div>

          <hr class="mg-y-100">
        </div><!-- az-profile-overview -->
      </div><!-- az-content-left -->

      <div class="az-content-body az-content-body-profile">
        <form class="az-tab-content" @submit.prevent="update">
          <div class="az-profile-body">
            <div class="row mg-b-20">
              <div class="col-md-12 col-xl-12">
                <div class="az-profile-view-chart">
                  <div class="az-profile-view-info col-md-12">
                    <div class="d-flex align-items-baseline">
                      <h6>Organization Defaults</h6>
                      <div class="mg-l-auto">
                        <router-link class="router-link-active" href="/myorg/" :to="{name: 'myorg' }">
                          <i class="fa fa-arrow-left"></i>
                          Back
                        </router-link>
                      </div>
                    </div>
                    <p>Please keep your profile information always current in order to help you when you contact us.</p>
                    <div class="mg-t-35">
                      <div class="row row-xs align-items-center mg-b-20">
                        <div class="col-md-6 col-lg-6 h-100-px">
                          <label class="form-control-label">Currency: </label>
                          <select class="form-control rounded" :class="{ 'invalid-field' : submitted && errors.first('currency')}" data-vv-as="Currency" id="currency" name="currency" v-validate="'required'" v-model="defaults.currency_id">
                            <option :value="null">Select Currency</option>
                            <option v-for="currency in currencies" :value="currency.currency">
                              {{currency | value('name')}} - {{currency | value('symbol')}} - {{currency | value('symbol_native')}}
                            </option>
                          </select>
                          <div class="invalid-feedback d-block" v-if="submitted && errors.has('currency')">{{ errors.first('currency')}}</div>
                        </div>
                        <div class="col-md-6 col-lg-6 mg-t-20 mg-md-t-0 h-100-px">
                          <label class="form-control-label">Date Format: </label>
                          <input class="form-control rounded" data-vv-as="Date Format" :class="{ 'invalid-field' : submitted && errors.first('date_format')}" id="date_format" name="date_format" placeholder="Enter Date Format" type="text" v-validate="'required'" v-model="defaults.date_format">
                          <div class="invalid-feedback d-block" v-if="submitted && errors.has('date_format')">{{ errors.first('date_format')}}</div>
                        </div>
                      </div>
                      <div class="row row-xs align-items-center mg-b-20">
                        <div class="col-md-6 col-lg-6 h-100-px">
                          <label class="form-control-label">Timezone: </label>
                          <select class="form-control rounded" data-vv-as="Timezone" :class="{ 'invalid-field' : submitted && errors.first('timezone')}" id="timezone" @change="setTimezoneOffset" v-validate="'required'" name="timezone" v-model="defaults.timezone">
                            <option :value="null">Select Timezone</option>
                            <option v-for="timezone in timezones" :value="timezone.abbr">{{timezone.abbr}} - {{timezone.value}}</option>
                          </select>
                          <div class="invalid-feedback d-block" v-if="submitted && errors.has('timezone')">{{ errors.first('timezone')}}</div>
                        </div>
                        <div class="col-md-6 col-lg-6 mg-t-20 mg-md-t-0 h-100-px">
                          <label class="form-control-label">Timezone Offset: </label>
                          <select class="form-control rounded" data-vv-as="Timezone Offset" :class="{ 'invalid-field' : submitted && errors.first('timezone_offset')}" id="timezone_offset" name="timezone_offset" disabled v-validate="'required'" v-model="defaults.timezone">
                            <option :value="null">Select Timezone Offset</option>
                            <option v-for="timezone in timezones" :value="timezone.abbr">{{timezone.text}}</option>
                          </select>
                          <div class="invalid-feedback d-block" v-if="submitted && errors.first('timezone_offset')">{{ errors.first('timezone_offset')}}</div>
                        </div>
                      </div>
                      <div class="row row-sm form-group mg-t-10">
                        <div class="col-lg-3 offset-lg-10">
                          <button type="submit" class="btn btn-orange rounded w-80 font-weight-bold mg-l-35" title="Add Catalog">
                            <i class="fas fa-save" />&nbsp;&nbsp;Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mg-b-20"></div>

          </div><!-- az-profile-body -->
        </form>
      </div><!-- az-content-body -->
    </div><!-- container -->
  </div><!-- az-content -->
</template>

<script>
import { mapGetters } from "vuex";
import timezones from "../../../assets/data/timezones";
import EventBus from "@/event-bus";

export default {
  name: "Edit",
  computed: {
    ...mapGetters(["currentUser", "currentOrg"])
  },
  data() {
    return {
      submitted: false,
      currencies: [],
      timezones: timezones,
      defaults: {
        currency_id: null,
        date_format: null,
        timezone: null,
        timezone_offset: null
      },
      accountDetails: {}
    };
  },
  mounted() {
    this.currencies = JSON.parse(localStorage.getItem('currencies'));
    this.init();
  },
  methods: {
    async init() {
      let reply = await this.$accounts.get(`/accounts/org`);
      this.accountDetails = reply.data;
      reply = await this.$accounts.get(`/accounts/org/defaults`);
      this.defaults = reply.data;
      this.defaults.timezone_offset = Number(reply.data.timezone_offset);
    },
    setTimezoneOffset(e) {
      let timezone = _.filter(timezones, { abbr: e.target.value });
      timezone = timezone[0] || {};
      this.defaults.timezone_offset = timezone.offset;
    },
    async update() {
      this.submitted = true;
      let valid = await this.$validator.validate();

      if (valid) {
        try {
          EventBus.$emit('openLoader');
          await this.$accounts.post(`/accounts/org/defaults`, {
            currency_id: this.defaults.currency_id,
            date_format: this.defaults.date_format,
            timezone: this.defaults.timezone,
            timezone_offset: this.defaults.timezone_offset
          });
          this.$toastr.s("Organization Defaults Successfully Updated!", 'Success');
          await this.$router.push('/myorg');
        } catch (e) {
          console.log(e);
        } finally {
          EventBus.$emit('closeLoader');
        }
      }
    }
  }
}
</script>

<style scoped>
.invalid-field {
  border-color: #dc3545;
}

.h-100-px {
  height: 80px;
}
</style>